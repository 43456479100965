.DisplayListingEachCon1 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  display: inline-flex;
}

.DisplayListingEachCon2 {
  background: linear-gradient(#3a7d44 0%, #3a7d441a 100%);
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  height: 42px;
  padding: 6px 12px;
  display: flex;
}

.DisplayListingEachCon3 {
  background: linear-gradient(270deg, #3a7d44 0%, #3a7d4400 100%);
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 3px 6px;
  display: inline-flex;
}

.White {
  transform-origin: 0 0;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 1.15px 1.17px 1.15px .57px;
  display: flex;
  transform: rotate(-180deg);
}

.Vector {
  background: #fff;
  width: 22.26px;
  height: 21.69px;
}

.Regresar {
  color: #fff;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
}

.DisplayListingEachCon4 {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  overflow: hidden scroll;
}

.DisplayListingEachTopOtherListingsCon {
  align-self: stretch;
  padding: 10px;
}

.DisplayListingEachCon5 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.DisplayListingEachBottomOtherListingsCon {
  align-self: stretch;
  height: 36px;
}

.ListingDisplayImagesDisplayCon1 {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 12px 0;
  display: flex;
  overflow: hidden;
}

.ListingDisplayImagesDisplayCon2 {
  scroll-behavior: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  align-self: stretch;
  align-items: center;
  display: flex;
  overflow-x: scroll;
}

.ListingDisplayImagesDisplayCon3 {
  align-items: center;
  min-width: 100%;
  display: flex;
}

.ListingDisplayImagesDisplayImageForEachImg {
  min-width: 95%;
  padding: 0 3px;
}

.ListingDisplayImagesDisplayImage {
  object-fit: cover;
  scroll-snap-align: center;
  border-radius: 12px;
  width: 100%;
  height: 250px;
  transition: width .3s, height .3s;
  position: relative;
}

.ListingDisplayImagesDisplayImageNoneActive {
  height: 238px;
}

.ListingDisplayImagesDisplayCon4 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding-left: 12px;
  padding-right: 12px;
  display: inline-flex;
}

.ListingDisplayImagesDisplayCon5 {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.ListingDisplayImagesDisplayDotIndicator {
  background: #939393;
  border-radius: 9999px;
  width: 12px;
  height: 12px;
  transition: all .3s;
}

.ListingDisplayImagesDisplayDotIndicatorActive {
  background: #000;
  border-radius: 9999px;
  width: 10px;
  height: 10px;
}

.ListingDisplayImagesDisplayButtonImgHide {
  display: none;
}

@media (width >= 500px) {
  .ListingDisplayImagesDisplayButtonImgBack {
    flex: 1 0 0;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 3px 12px;
    display: flex;
  }

  .ListingDisplayImagesDisplayButtonImgAhead {
    flex: 1 0 0;
    align-items: center;
    gap: 10px;
    padding: 3px 12px;
    display: flex;
  }
}

.DisplayListingEachCon6 {
  box-sizing: border-box;
  background: linear-gradient(0deg, #3a7d44 0% 100%);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  padding: 12px 12px 6px;
  display: inline-flex;
}

.DisplayListingEachCon7 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: inline-flex;
}

.DisplayListingEachButtonActio {
  background: #fff;
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 28px;
  padding: 6px 12px;
  display: inline-flex;
  position: relative;
  box-shadow: 0 4px 4px #00000040;
}

.DisplayListingEachButtonActioIcons {
  height: 24px;
}

.DisplayListingEachButtonActioIconsStats {
  height: 12px;
}

.DisplayListingEachCon8 {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.DisplayListingEachCon9 {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  display: flex;
}

.DisplayListingEachCon10 {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.DisplayListingEachStatsCon {
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
}

.DisplayListingEachStatsConText {
  color: #fff;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}

.DisplayListingEachCon11 {
  box-sizing: border-box;
  background: #ff7043;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 6px 10px;
  display: inline-flex;
}

.DisplayListingEachItemTitle {
  color: #fff;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
}

.DisplayListingEachCon12 {
  box-sizing: border-box;
  background: #e0e0e0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  padding: 24px 12px;
  display: inline-flex;
}

.DisplayListingEachCon13 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.DisplayListingEachCon13B {
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.DisplayListingEachCon13C {
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.DisplayListingEachCon13CText {
  color: var(--app-link-color, #00349b);
  letter-spacing: .16px;
  text-decoration-skip-ink: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
}

.DisplayListingEachCon14 {
  flex-direction: column;
  flex: 1 1 0;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
}

.DisplayListingEachCon15 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.DisplayListingEachCon16 {
  background: linear-gradient(360deg, #3a7d44 0%, #69e37b 100%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px 4px;
  display: inline-flex;
}

.DisplayListingEachCon16Text {
  color: #2b2b2b;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}

.DisplayListingEachItemPrice {
  color: #3a7d44;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
}

.DisplayListingEachItemCondition {
  color: #3a7d44;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
}

.DisplayListingEachCon17 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 2px;
  display: inline-flex;
}

.DisplayListingEachCon17Text {
  color: #ff7043;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}

.DisplayListingEachItemBeforePrice {
  color: #ff7043;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
}

.DisplayListingEachCon18 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.DisplayListingEachCon19 {
  background: #fff;
  border-bottom: 1px solid #000;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 6px;
  display: inline-flex;
}

.DisplayListingEachCon19Text {
  color: #000;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
}

.DisplayListingEachCon20 {
  opacity: 1;
  background: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  max-height: 210px;
  padding: 12px;
  transition: max-height .3s ease-out, opacity .3s ease-out, padding .3s ease-out;
  display: flex;
  overflow: hidden;
}

.DisplayListingEachtechnicalInfoHide {
  opacity: 0;
  max-height: 0;
  padding: 0;
  overflow: hidden;
}

.DisplayListingEachCon21 {
  border-bottom: .5px solid #000;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 6px;
  display: inline-flex;
}

.DisplayListingEachCon21Text1 {
  color: #6d6d6d;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
}

.DisplayListingEachCon21Text2 {
  color: #000;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
}

.DisplayListingEachCon22 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.DisplayListingEachCon22Text {
  color: #000;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
}

.DisplayListingEachCon23 {
  background: #fff;
  border-radius: 6px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 12px;
  display: inline-flex;
}

.DisplayListingEachItemDescription {
  color: #6d6d6d;
  letter-spacing: .16px;
  word-wrap: break-word;
  flex: 1 1 0;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.DisplayListingEachCon24 {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.DisplayListingEachCon24Text {
  color: #000;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
}

.DisplayListingEachCon25 {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}
/*# sourceMappingURL=index.bcbbf58b.css.map */
