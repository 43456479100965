/* Start Initial con */

.DisplayListingEachCon1 {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}

.DisplayListingEachCon2 {
  align-self: stretch;
  height: 42px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: linear-gradient(180deg, #3a7d44 0%, rgba(58, 125, 68, 0.1) 100%);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.DisplayListingEachCon3 {
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  background: linear-gradient(270deg, #3a7d44 0%, rgba(58, 125, 68, 0) 100%);
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}

.White {
  width: 24px;
  height: 24px;
  padding-top: 1.15px;
  padding-bottom: 1.15px;
  padding-left: 0.57px;
  padding-right: 1.17px;
  transform: rotate(-180deg);
  transform-origin: 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Vector {
  width: 22.26px;
  height: 21.69px;
  background: white;
}

.Regresar {
  color: white;
  font-size: 20px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.DisplayListingEachCon4 {
  width: 100%; /* Default width is 100% of the viewport */
  max-width: 500px; /* Maximum width is 500px */
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0 auto;
  box-sizing: border-box; /* Ensures padding/border are included in width */
}

.DisplayListingEachTopOtherListingsCon {
  align-self: stretch;
  padding: 10px;
}
.DisplayListingEachCon5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.DisplayListingEachBottomOtherListingsCon {
  align-self: stretch;
  height: 36px;
}
/* End Initial con */

/* Start Image Displayer */
.ListingDisplayImagesDisplayCon1 {
  width: 100%;
  display: flex;
  /* max-width: 500px; */

  padding: 12px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow: hidden;
}

.ListingDisplayImagesDisplayCon2 {
  display: flex;
  align-items: center;
  align-self: stretch;
  overflow-x: scroll;
  scroll-behavior: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /*Improves mobile smoothness*/
}

.ListingDisplayImagesDisplayCon3 {
  min-width: 100%;
  display: flex;
  align-items: center;
  /* align-self: stretch; */
}

.ListingDisplayImagesDisplayImageForEachImg {
  min-width: 95%;
  padding: 0px 3px;
}

.ListingDisplayImagesDisplayImage {
  width: 100%;
  object-fit: cover;
  /* height: 100%; */
  height: 250px;
  position: relative;
  border-radius: 12px;
  transition: width 0.3s ease, height 0.3s ease;
  scroll-snap-align: center; /* Images align at the center*/
}

.ListingDisplayImagesDisplayImageNoneActive {
  height: 238px;
}

.ListingDisplayImagesDisplayCon4 {
  align-self: stretch;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}

.ListingDisplayImagesDisplayCon5 {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.ListingDisplayImagesDisplayDotIndicator {
  width: 12px;
  height: 12px;
  background: #939393;
  border-radius: 9999px;
  transition: all 0.3s ease; /* Add transition for smooth effect */
}

.ListingDisplayImagesDisplayDotIndicatorActive {
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 9999px;
}

.ListingDisplayImagesDisplayButtonImgHide {
  display: none;
}

@media (min-width: 500px) {
  .ListingDisplayImagesDisplayButtonImgBack {
    display: flex;
    padding: 3px 12px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
  }
  .ListingDisplayImagesDisplayButtonImgAhead {
    display: flex;
    padding: 3px 12px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
  }
}

/* End Image Displayer */
/* Start Rest Displayer */

.DisplayListingEachCon6 {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  background: linear-gradient(0deg, #3a7d44 0%, #3a7d44 100%);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  box-sizing: border-box;
}

.DisplayListingEachCon7 {
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}

.DisplayListingEachButtonActio {
  width: 28px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}
.DisplayListingEachButtonActioIcons {
  height: 24px;
}
.DisplayListingEachButtonActioIconsStats {
  height: 12px;
}

.DisplayListingEachCon8 {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.DisplayListingEachCon9 {
  /* align-self: stretch;
  max-width: 500px;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex; */
  width: 100%;
  max-width: 800px;
  /* align-self: stretch; */
  justify-content: space-between; /* Maintains spacing between inner items */
  align-items: center; /* Centers inner items vertically */
  display: flex;
}

.DisplayListingEachCon10 {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.DisplayListingEachStatsCon {
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: flex;
}

.DisplayListingEachStatsConText {
  color: white;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.DisplayListingEachCon11 {
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: #ff7043;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}

.DisplayListingEachItemTitle {
  color: white;
  font-size: 22px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.DisplayListingEachCon12 {
  width: 100%;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 24px;
  padding-bottom: 24px;
  background: #e0e0e0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
}

.DisplayListingEachCon13 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.DisplayListingEachCon13B {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}
.DisplayListingEachCon13C {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.DisplayListingEachCon13CText {
  flex: 1 0 0;
  color: var(--app-link-color, #00349b);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.16px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.DisplayListingEachCon14 {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
}

.DisplayListingEachCon15 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.DisplayListingEachCon16 {
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: linear-gradient(360deg, #3a7d44 0%, #69e37b 100%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.DisplayListingEachCon16Text {
  color: #2b2b2b;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.DisplayListingEachItemPrice {
  color: #3a7d44;
  font-size: 20px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
}

.DisplayListingEachItemCondition {
  color: #3a7d44;
  font-size: 12px;
  font-family: Inter;
  font-weight: 300;
  word-wrap: break-word;
}

.DisplayListingEachCon17 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 2px;
  display: inline-flex;
}

.DisplayListingEachCon17Text {
  color: #ff7043;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.DisplayListingEachItemBeforePrice {
  color: #ff7043;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.DisplayListingEachCon18 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.DisplayListingEachCon19 {
  align-self: stretch;
  padding: 6px;
  background: white;
  border-bottom: 1px black solid;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}

.DisplayListingEachCon19Text {
  color: black;
  font-size: 18px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.DisplayListingEachCon20 {
  align-self: stretch;
  padding: 12px;
  background: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out,
    padding 0.3s ease-out;
  max-height: 210px; /* Arbitrary max-height when visible */
  opacity: 1;
}

.DisplayListingEachtechnicalInfoHide {
  max-height: 0; /* Shrinks height */
  opacity: 0; /* Fades out */
  overflow: hidden;
  padding: 0; /* Optionally remove padding */
}

.DisplayListingEachCon21 {
  align-self: stretch;
  padding: 6px;
  border-bottom: 0.5px black solid;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}

.DisplayListingEachCon21Text1 {
  color: #6d6d6d;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.DisplayListingEachCon21Text2 {
  color: black;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.DisplayListingEachCon22 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.DisplayListingEachCon22Text {
  color: black;
  font-size: 18px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.DisplayListingEachCon23 {
  align-self: stretch;
  padding: 12px;
  background: white;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.DisplayListingEachItemDescription {
  flex: 1 1 0;
  color: #6d6d6d;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.16px;
  word-wrap: break-word;
}
.DisplayListingEachCon24 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}
.DisplayListingEachCon24Text {
  color: black;
  font-size: 18px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}
.DisplayListingEachCon25 {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
}
/* End Rest Displayer */
